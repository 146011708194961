/**
 * @module ArticleCard
 */
import React from 'react';
import { fetchArticleCard } from '../../helpers/dataFetchers/findsFetchers';
import CardWithTags from '../CardWithTags/CardWithTags';

/**
 * Represents a component that fetches and displays an article card with tags.
 *
 * @param {object} props - The component props object.
 * @param {string} props.article - The article uuid of which to fetch data.
 * @param {object} [props.fetchedArticle] - Optional prefetched article data.
 * @param {boolean} [props.isFullImage] - The boolean flag indicating whether to display the image in full width.
 * @param {boolean} [props.isSearchResult] - The boolean flag indicating whether the card is being displayed as a search result.
 * @param {object} [props.preload] - Optional preloaded article data.
 * @param {string} props.theme - The theme value for styling the card.
 *
 * @returns {React.ReactElement} - The ArticleCard component.
 */
function ArticleCard({
  article,
  fetchedArticle,
  isFullImage = false,
  isSearchResult = false,
  preload,
  theme,
}) {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      if (!(fetchedArticle || preload)) {
        setData(await fetchArticleCard({ article }));
      } else {
        setData(fetchedArticle || preload);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  return data ? (
    <CardWithTags
      author={data.author}
      description={data.description}
      duration={data.duration}
      hasFullWidthImage={isFullImage}
      image={data.featuredImage}
      isArticle={true}
      isSearchResult={isSearchResult}
      link={data.path ?? (data['@name'] && `/${data['@name']}`)}
      tags={data['mgnl:tags'] || []}
      theme={theme}
      title={data.title}
    />
  ) : null;
}

export default ArticleCard;

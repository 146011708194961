/* eslint-disable no-unused-vars */
/**
 * @module SearchTagFilters
 */
import React from 'react';
import TagFilter from './TagFilter';
import './SearchTagFilters.scss';

/**
 * Renders a list of tag filters for search functionality.
 *
 * @param {object} props - The component props object.
 * @param {string|undefined} props.activeFilter - The currently active filter tag.
 * @param {string[]} props.tagFilters - An array of tag filter options.
 * @param {Function} props.handleTagClick - Callback function to handle tag click events.
 *
 * @returns {React.ReactElement|null} A list of TagFilter components or null if no filters are available.
 */
function SearchTagFilters({ activeFilter, tagFilters, handleTagClick }) {
  if (!tagFilters?.length) {
    return null;
  }

  return (
    <ul className="search-tag-filters">
      {tagFilters.map((tagFilter) => (
        <TagFilter
          activeFilter={activeFilter}
          handleTagClick={handleTagClick}
          key={tagFilter}
          tagFilter={tagFilter}
        />
      ))}
    </ul>
  );
}

export default SearchTagFilters;

/**
 * @module Search
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { InstantSearch, useHits, useSearchBox } from 'react-instantsearch';
import { ACTIONS, EVENTS, ZERO_RESULTS_TEXT } from '../../helpers/constants';
import useAlgoliaClient from '../../hooks/useAlgoliaClient';
import FindsSearchBox from '../FindsSearchBox/FindsSearchBox';
import ArticleCard from '../ArticleCard/ArticleCard';
import './Search.scss';

/**
 * Component that displays and manages article hits.
 *
 * @param {object} props - The component props object.
 * @param {string} props.appliedTheme - The current theme applied to the component.
 * @param {number} props.hitNum - The current hit number.
 * @param {number} props.numToDisplay - The number of hits to display.
 * @param {Function} props.setHitNum - Function to update the hit number.
 *
 * @returns {React.ReactElement} - The ArticleHits component.
 */
function ArticleHits({ appliedTheme, hitNum, numToDisplay, setHitNum }) {
  const getArticlesToDisplay = (list) => {
    if (!list?.length) {
      return list;
    }
    const copy = [...list];
    return copy.splice(0, numToDisplay);
  };

  /**
   * Convenience function to transform and filters the items returned from the search.
   */
  const transformItems = React.useCallback(
    (items) => {
      if (!items?.length) {
        setHitNum(0);
        return items;
      }
      let transformed = items.map((item) => {
        const newItem = item;
        const id = item.objectID;
        const n = id.lastIndexOf('/');
        newItem.data['@name'] = id.substring(n + 1);
        return newItem;
      });
      if (transformed.length !== hitNum) {
        setHitNum(transformed.length);
      }
      transformed = getArticlesToDisplay(transformed);
      return transformed;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [numToDisplay],
  );

  const { hits } = useHits({ transformItems });
  const { query } = useSearchBox();
  const searchClient = useAlgoliaClient();
  const [lastThree, setLastThree] = React.useState([]);

  /**
   * Convenience effect to fetch the last three articles from the Algolia search index on component mount.
   */
  React.useEffect(() => {
    searchClient
      .search([
        {
          indexName: process.env.FINDS_ALGOLIA_INDEX_ARTICLES,
          params: {
            hitsPerPage: 3,
          },
        },
      ])
      .then((result) => {
        setLastThree(result?.results?.[0]?.hits);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hits?.length === 0 && query && lastThree?.length) {
    return (
      <div className="no-results-wrapper">
        <p>{ZERO_RESULTS_TEXT}</p>
        {lastThree.map((hit) => (
          <ArticleCard
            fetchedArticle={hit.data}
            key={hit.objectID}
            theme={appliedTheme}
          />
        ))}
      </div>
    );
  }

  return (hits || []).map((hit) => (
    <ArticleCard
      fetchedArticle={hit.data}
      key={hit.objectID}
      theme={appliedTheme}
    />
  ));
}

const Search = ({ searchModalHandler }) => {
  const { user } = useAuth();
  const searchClient = useAlgoliaClient();
  const [hitNum, setHitNum] = React.useState(0);
  const [numToDisplay, setNumToDisplay] = React.useState(4);
  const appliedTheme = 'light-mode';

  /**
   * Convenience function to trigger callSegmentTrack.
   *
   * @param {object} params - The function params object.
   * @param {string} [params.action] - Optional value for action.
   * @param {string} [params.eventName] - Optional event associated with the tracking request.
   * @param {string} [params.label] - Optional value for label.
   * @param {string} [params.value] - Optional value associated with the tracking request.
   */
  function callAnalytics({ action, eventName, label, value }) {
    callSegmentTrack({
      event: eventName || EVENTS.buttonAction,
      properties: {
        action: action || ACTIONS.clicked,
        component: 'Search',
        component_url: null, // Inner-component scroll, no component URL.
        label,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value,
      },
    });
  }

  /**
   * Handler function for search close.
   *
   * @param {Event} event - The Event object associated with the action.
   */
  function handleSearchClose(event) {
    callAnalytics({
      action: EVENTS.buttonAction,
      event,
      label: 'Close',
    });
    searchModalHandler(event);
  }

  /**
   * Handler function for Load More button click event.
   */
  function loadMore() {
    callAnalytics({
      action: ACTIONS.clicked,
      eventName: EVENTS.buttonAction,
      label: 'Load More',
    });
    setNumToDisplay(numToDisplay + 4);
  }

  return (
    <div className="search-wrapper">
      <InstantSearch
        indexName={process.env.FINDS_ALGOLIA_INDEX_ARTICLES}
        searchClient={searchClient}
      >
        <div className="close-button-wrapper">
          <button className="action-button" onClick={handleSearchClose}>
            Close
          </button>
        </div>
        <div className="contents-container">
          <h2 className="title">Search</h2>
          <div className="flex-content">
            <FindsSearchBox
              isDisplayed={true}
              isFullSearch={true}
              theme={appliedTheme}
            />
          </div>
          <div className="searched-contents">
            <ArticleHits
              appliedTheme={appliedTheme}
              hitNum={hitNum}
              numToDisplay={numToDisplay}
              setHitNum={setHitNum}
            />
          </div>
          {hitNum > numToDisplay ? (
            <div className="load-button-wrapper">
              <button className="action-button" onClick={loadMore}>
                Load more
              </button>
            </div>
          ) : null}
        </div>
      </InstantSearch>
    </div>
  );
};

export default Search;
